/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CommunityBadgeLayout, CommunityIndicator, CommunityInfos } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Community<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags o-community-controller-api
   * @name GetBadgeLayout
   * @summary Получить информацию о статусе пользователя в O!Community для наполнения баннера текущего пользователя
   * @request GET:/api/v2/community/badge-layout
   */
  getBadgeLayout = (params: RequestParams = {}) =>
    this.request<CommunityBadgeLayout, any>({
      path: `/api/v2/community/badge-layout`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags o-community-controller-api
   * @name GetPrivilegesRequirements
   * @summary Получить информацию о каждом статусе, его привилегиях, и требованиях, общее для всех пользователей
   * @request GET:/api/v2/community/privileges-requirements
   */
  getPrivilegesRequirements = (params: RequestParams = {}) =>
    this.request<CommunityInfos, any>({
      path: `/api/v2/community/privileges-requirements`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags o-community-controller-api
   * @name GetUserPrivilegesRequirements
   * @summary Получить информацию о каждом статусе, его привилегиях, и относительные требования, для указанного пользователя
   * @request GET:/api/v2/community/privileges-requirements/{userId}
   */
  getUserPrivilegesRequirements = (userId: number, params: RequestParams = {}) =>
    this.request<CommunityInfos, void>({
      path: `/api/v2/community/privileges-requirements/${userId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags o-community-controller-api
   * @name GetStatus
   * @summary Получить информацию о количестве покупок и продаж для текущего пользователя
   * @request GET:/api/v2/community/transaction
   */
  getStatus = (params: RequestParams = {}) =>
    this.request<CommunityIndicator, any>({
      path: `/api/v2/community/transaction`,
      method: "GET",
      format: "json",
      ...params,
    });
}
