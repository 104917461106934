<template>
  <div
    class="o-community-badge"
    :class="{ 'cursor-pointer': enableDialog, 'dark-mode': darkMode }"
    :style="{ borderColor: borderColor }"
    @click="isDialogOpen = true"
  >
    <div
      v-if="modelValue.status"
      class="o-community-badge__status"
    >
      {{ modelValue.status?.name }}
    </div>

    <div
      v-for="(tag, index) in modelValue.tags"
      :key="tag!.code"
      class="o-community-badge__tag"
      :class="[`o-community-badge__tag--${tag!.code}`, { 'o-community-badge__tag--padding': !!(modelValue.status || index) }]"
      :style="{ borderColor: borderColor, zIndex: (modelValue.tags?.length || 0) - index }"
    >
      {{ tag?.name }}
    </div>

    <OCommunityDialog
      v-if="enableDialog"
      v-model="isDialogOpen"
      :user="user"
      :owner-mode="ownerMode"
    />
  </div>
</template>

<script setup lang="ts">
import { CommunityBadge, PublicProfileDTO } from '~/restAPI/data-contracts';

import OCommunityDialog from '~/components/account/OCommunityDialog.vue';

const props = defineProps<{
  modelValue: CommunityBadge
  user?: PublicProfileDTO
  withDialog?: boolean
  ownerMode?: boolean
  darkMode?: boolean
  borderColor?: string
}>();
const emits = defineEmits<{
  (e: 'mounted', value?: undefined): void
}>();

const isDialogOpen = ref(false);
const enableDialog = computed(() => props.withDialog && props.modelValue.status);

onMounted(() => emits('mounted'));
</script>

<style lang="scss" scoped>
@import "~/assets/scss/settings/index";

.o-community-badge {
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;
  font-size: 14px;
  line-height: 18px;
  color: white;
  font-family: $font-family-default;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 20px;
  }

  &__status,
  &__tag {
    position: relative;
    border: 2px solid white;
    border-radius: 20px;
    padding: 3px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.dark-mode:after {
    border-color: $primary;
  }

  &.dark-mode &__status,
  &.dark-mode &__tag {
    border-color: $primary;
    font-weight: 500;
  }

  &__status {
    z-index: 5;
    background: $primary;
  }

  &.dark-mode &__status {
    background: white;
    color: $primary;
  }

  &__tag {
    &--padding {
      padding-left: 24px;
      margin-left: -20px;
    }

    &--STYLIST {
      background: linear-gradient(262deg, #E0E0E0 -29.69%, #727272 116.47%);
    }
    &--INFLUENCER {
      background: linear-gradient(262deg, #E0E0E0 -29.69%, #D4B051 116.47%);
    }
    &--CELEBRITY {
      background: linear-gradient(262deg, #E0E0E0 -29.69%, #278EAE 116.47%);
    }
  }
}
</style>
