import UAParser from 'ua-parser-js';

export function useDevices() {
  const uaParser = new UAParser();
  const userDevice = uaParser.getDevice();
  const userOS = uaParser.getOS();

  const isMobile = [
    'mobile',
    'tablet',
  ].includes(userDevice.type || '');
  const isAppleDevice = userOS.name?.startsWith('Mac') || userOS.name?.startsWith('iOS') || false;
  const isAndroidDevice = userOS.name?.startsWith('Android') || false;

  return {
    isMobile,
    isAppleDevice,
    isAndroidDevice,
  };
}
